<template>
  <main>
    <div style="width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;">
      <div style="width: 50%; display: flex; flex-direction: row; margin-top: 1%;">
        <form class="input-group" @submit.prevent="filter()">
          <input type="search" class="form-control" v-model="search"
            placeholder="Buscar por título de vacante, unidad/división..." />
        </form>
        <!-- <div class="dropdown">
          <button class="btn border-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            Filtrar por Estado
          </button>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" @click="filterVacantes('Activa')">Activa</a></li>
            <li><a class="dropdown-item" @click="filterVacantes('En Pausa')">En Pausa</a></li>
            <li><a class="dropdown-item" @click="filterVacantes('Todas')">Todas</a></li>
          </ul>
        </div> -->
      </div>
      <div class="modal fade" id="modalVacante" tabindex="-1" aria-hidden="true" aria-labelledby="modalTitle"
        data-bs-backdrop="status">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modalTitle">
                Datos de La Vacante
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p style=" font-weight: bold; text-align: center;"> {{ viewDataArray.titulo }}
              </p>
              <p style="">Tipo de vacante: "{{ viewDataArray.type }}"</p>
              <p style="">Lugar de vacante: "{{ viewDataArray.ubicacion }}"</p>
              <p style="">Sueldo Ofrecido: {{ viewDataArray.becaDe }} -
                {{ viewDataArray.becaA }} MXN</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="aceptVacante()">
                Aceptar Vacante
              </button>
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteVacante()">
                Borrar Vacante
              </button>
            </div>
          </div>
        </div>
      </div>
      <table class="table align-middle table-bordered table-responsive table-hover content-top mt-5">
        <thead class="placeholder-glow">
          <tr style="text-align: center;">
            <th class="col" scope="col">Empresa</th>
            <th class="col" scope="col">Nombre de Vacante</th>
            <th class="col" scope="col">Fecha Creada</th>
            <th class="col" scope="col">Vigencia</th>
            <th class="col" scope="col">Estado</th>
            <th class="col" scope="col">Postulados</th>
            <th class="col" scope="col">Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="vacante in dataPage" v-bind:key="vacante">
            <td v-bind:key="vacante.authorVacante.nameCompany" style="text-align: center;">
              {{ vacante.authorVacante.nameCompany }}
            </td>
            <td v-bind:key="vacante.titulo" style="text-align: center;">
              {{ vacante.titulo }}
            </td>
            <td align="center"> {{ moment(vacante.createdAt).format("DD/MM/YYYY") }}</td>
            <td align="center">
              <span class="badge" :class="{ 'bg-danger': moment(vacante.createdAt).isBefore(this.vigencia), 'bg-success': moment(vacante.createdAt).isSameOrAfter(this.vigencia) }">
                {{ moment(vacante.createdAt).isBefore(this.vigencia) ? "Vacante Caducada" : "Vacante Vigente" }}
              </span>
            </td>

            <td style="text-align: center;">{{ vacante.estado }}</td>
            <td style="text-align: center;">{{ vacante.postulates }}</td>
            <td align="center">
              <button type="button" data-bs-target="#modalVacante" data-bs-toggle="modal"
                class="btn btn-icon btn-success btn-hover-primary btn-sm mx-3" @click="viewData(vacante)"
                title="Ver Vacante">
                <box-icon name='show' type="solid" color="white"></box-icon>
              </button>
            </td>
          </tr>
          <div class="modal fade" id="miModal2" tabindex="-1" aria-hidden="true" aria-labelledby="modalTitle"
            data-bs-backdrop="status">
          </div>
        </tbody>
      </table>
      <!-- Paginador -->
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item" v-on:click="getPreviousPage()">
            <a class="page-link" href="#">Anterior</a>
          </li>
          <li v-for="(page, i) in totalPagesNumber" :key="i + 1" v-on:click="getDataPages(page)"
            v-bind:class="isActive(page)" class="page-item">
            <a class="page-link" href="#">{{ page }}</a>
          </li>
          <li class="page-item" v-on:click="getNextPage()">
            <a class="page-link" href="#">Siguiente</a>
          </li>
        </ul>
      </nav>
    </div>
  </main>
</template>

<script>
import { AdminService } from "@/services";
import moment from "moment";


moment.locale("es");
//var bootstrap = window.bootstrap;
export default {
  data: () => ({
    table: null,
    elementSearch: "",
    elementPage: 15,
    dataPage: [],
    viewDataArray: {
      createdAt: new Date(),
    },
    actual: 1,
    search: "",
    id: "",
    data: {},
    listVacantes: {},
    listVacantesTemp: {},
    modalVacantes: null,
    moment: moment,
    estadoPendiente: "Pendiente",
    estadoTodo: "Todo",
    estadoAceptado: "Aceptado",
    totalPagesNumber: 0,
    estadoFiltro: "Todas",
    vigencia: new Date(moment(new Date()).subtract('2', 'months').format('YYYY-MM-DD hh:mm:ss')),
  }),
  mounted() {
    this.vacantes();
  },
  methods: {
    initialModal() {
      this.modalVacantes = new window.bootstrap.Modal(
        document.getElementById("modalVacante"),
        {
          keyboard: false,
        }
      );
    },
    async vacantes() {
      await AdminService.getVacantes({ search: this.search }).then((resp) => {
        this.listVacantes = resp.data.data
        this.listVacantesTemp = resp.data.data
        this.totalPages(this.listVacantes.length)
        this.listVacantes.length > 0 ? this.getDataPages(1) : this.response.loading = true;
      });
    },
    viewData(vacante) {
      this.viewDataArray = vacante;
      this.id = vacante._id;
    },
    aceptVacante() {
      let data = { id: this.id, status: this.status };
      AdminService.aceptVacante(data)
        .then((res) => {
          this.$swal({
            position: "top-center",
            icon: "success",
            title: res.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          this.vacantes();
        })
        .catch((e) => {
          this.$swal({
            position: "top-end",
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    },
    deleteVacante() {
      AdminService.deleteVacante({ id: this.id })
        .then((res) => {
          this.$swal({
            position: "top-center",
            icon: "success",
            title: res.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
          this.vacantes();
        })
        .catch((e) => {
          this.$swal({
            position: "top-end",
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    },
    async totalPages(data) {
      this.totalPagesNumber = Math.ceil(data / 20);
    },
    getDataPages(numberPage) {
      this.actual = numberPage;
      const start = numberPage * this.elementPage - this.elementPage;
      const end = numberPage * this.elementPage;
      this.dataPage = this.listVacantes.slice(start, end);
    },
    getPreviousPage() {
      if (this.actual > 1) {
        this.actual--;
      }
      this.getDataPages(this.actual);
    },
    getNextPage() {
      if (this.actual < this.totalPages()) {
        this.actual++;
      }
      this.getDataPages(this.actual);
    },
    isActive(numberPage) {
      return numberPage == this.actual ? "active" : "";
    },
    filter() {
      if (this.search !== "") {
        const search = this.search.toLowerCase();
        const filteredVacantes = this.listVacantes.filter(element => {
          const nameCompany = (element.authorVacante.nameCompany || "").toLowerCase();
          const titulo = (element.titulo || "").toLowerCase();
          const ubicacion = (element.ubicacion || "").toLowerCase();
          return titulo.includes(search) || ubicacion.includes(search) || nameCompany.includes(search);
        });
        this.totalPages(this.dataPage.length)
        return this.dataPage = filteredVacantes;
      } else {
        return this.vacantes();
      }
    },
    filterVacantes(estado) {
      this.estadoFiltro = estado;
      switch (estado) {
        case "Activa":
          this.listVacantes = this.listVacantesTemp.filter(vacante => !vacante.vacanteSuspended);
          break;
        case "En Pausa":
          this.listVacantes = this.listVacantesTemp.filter(vacante => vacante.vacanteSuspended);
          break;
        case "Todas":
          this.listVacantes = this.listVacantesTemp;
          break;
      }
    }
  },
};
</script>
